// @flow
// base
// import { ArcherContainer, ArcherElement } from 'react-archer'
import React from 'react'
import cn from 'classnames'
import { useIntl } from 'gatsby-plugin-intl'
import messages from './TsboInfographic.lang'
import ScrollAnimation from 'react-animate-on-scroll'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
// components
import { Container } from '../../../../components/basic/Grid'
import IconItem from './../../IconItem'
// assets
import peakworkSource from '../../../../images/products/source-peakwork.svg'
import peakworkLogo from '../../../../images/products/logo-peakwork.svg'
import amadeusSource from '../../../../images/products/source-amadeus.svg'
import amadeusLogo from '../../../../images/products/logo-amadeus.svg'
import bewotecSource from '../../../../images/products/source-bewotec.svg'
import bewotecLogo from '../../../../images/products/logo-bewotec.svg'
import sabreSource from '../../../../images/products/source-sabre-synxis.svg'
import sabreLogo from '../../../../images/products/logo-sabre-synxis.svg'
import trafficsSource from '../../../../images/products/source-traffics.svg'
import trafficsLogo from '../../../../images/products/logo-traffics.svg'
import nezasaSource from '../../../../images/products/source-nezasa.svg'
import nezasaLogo from '../../../../images/products/logo-nezasa.svg'
import productDesktop from '../../../../images/products/tsbo/tsbo-teaser2-desktop.png'
import productTablet from '../../../../images/products/tsbo/tsbo-teaser2-tablet.png'
import productTablet2x from '../../../../images/products/tsbo/tsbo-teaser2-tabletx2.png'
import productMobile from '../../../../images/products/tsbo/tsbo-teaser2-mobile.png'
import productMobile2x from '../../../../images/products/tsbo/tsbo-teaser2-mobilex2.png'
import arrowHotels from '../../../../images/products/tsbo/infographic-tsbo-arrow-hotels.svg'
import arrowFlights from '../../../../images/products/tsbo/infographic-tsbo-arrow-flights.svg'
import arrowActivites from '../../../../images/products/tsbo/infographic-tsbo-arrow-activities.svg'
import arrowSources from '../../../../images/products/tsbo/infographic-tsbo-arrow-sources.svg'
// styling
import baseStyles from './TsboInfographic.scss'

type Props = {
  ...StyleProps,
  title?: Object,
  titleImg?: Object,
  contentText?: Object
}

const TsboInfographic = ({ styles, title, titleImg, contentText }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <Container className={styles.container} fluid>
        <div className={styles.products}>
          <h2 className={styles.title}>
            {title ? <span className={styles.titleWithImage}>{title}</span> : formatMessage(messages.title)}
            {titleImg && <img className={styles.titleImg} src={titleImg} alt="title image" />}
          </h2>
          <span className={styles.contentText}>{contentText ? contentText : formatMessage(messages.contentText)}</span>
          <div className={styles.productList}>
            <ScrollAnimation animateIn="fadeInUp" animateOnce delay={100}>
              <span className={styles.subtitle}>
                {formatMessage(messages.subtitle)}
                <b className={styles.lastLetter}>.</b>
              </span>
              <div className={styles.productIcons}>
                <div className={cn(styles.productIcon, styles.hotelIcon)}>
                  <span className={cn(styles.iconWrapper, styles['is-black'])}>
                    <span className={cn(styles.icon, 'icon-hotel')} />
                  </span>
                  <span className={styles.label}>{formatMessage(messages.icon1Label)}</span>
                  <img className={cn(styles.productArrow, styles.arrowHotel)} src={arrowHotels} alt="image arrows" />
                </div>
                <div className={cn(styles.productIcon, styles.flightIcon)}>
                  <span className={cn(styles.iconWrapper, styles['is-black'])}>
                    <span className={cn(styles.icon, 'icon-plane')} />
                  </span>
                  <span className={styles.label}>{formatMessage(messages.icon2Label)}</span>
                  <img className={cn(styles.productArrow, styles.arrowFlights)} src={arrowFlights} alt="image arrows" />
                </div>
                <div className={cn(styles.productIcon, styles.activityIcon)}>
                  <span className={cn(styles.iconWrapper, styles['is-black'])}>
                    <span className={cn(styles.icon, 'icon-surfing')} />
                  </span>
                  <span className={styles.label}>{formatMessage(messages.icon3Label)}</span>
                  <img
                    className={cn(styles.productArrow, styles.arrowActivities)}
                    src={arrowActivites}
                    alt="image arrows"
                  />
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
        <div className={styles.infographic}>
          <div className={styles.colLeft}>
            <ScrollAnimation animateIn="fadeIn" animateOnce delay={200}>
              <span className={styles.subtitle}>
                {formatMessage(messages.subtitle2)}
                <b className={styles.lastLetter}>.</b>
              </span>
              <div className={styles.sourceIconWrapper}>
                <div className={styles.sourceItem}>
                  <span className={cn(styles.sourceIcon, styles.sourceIcon)}>
                    <img className={styles.sourceIconImg} src={peakworkSource} alt="image pearkwork source" />
                  </span>
                  <span className={styles.sourceLogo}>
                    <img className={styles.sourceLogoImg} src={peakworkLogo} alt="peakwork logo" />
                  </span>
                </div>
                <div className={styles.sourceItem}>
                  <span className={cn(styles.sourceIcon, styles.amadeusIcon)}>
                    <img className={styles.sourceIconImg} src={amadeusSource} alt="image amadeus source" />
                  </span>
                  <span className={styles.sourceLogo}>
                    <img className={styles.sourceLogoImg} src={amadeusLogo} alt="amadeus logo" />
                  </span>
                </div>
                <div className={styles.sourceItem}>
                  <span className={cn(styles.sourceIcon, styles.bewotecIcon)}>
                    <img className={styles.sourceIconImg} src={bewotecSource} alt="bewotec source" />
                  </span>
                  <span className={styles.sourceLogo}>
                    <img className={styles.sourceLogoImg} src={bewotecLogo} alt="bewotec logo" />
                  </span>
                </div>
                <div className={styles.sourceItem}>
                  <span className={cn(styles.sourceIcon, styles.sabreIcon)}>
                    <img className={styles.sourceIconImg} src={sabreSource} alt="Sabre SynXis source" />
                  </span>
                  <span className={styles.sourceLogo}>
                    <img className={styles.sourceLogoImg} src={sabreLogo} alt="Sabre SynXis logo" />
                  </span>
                </div>
                <div className={styles.sourceItem}>
                  <span className={cn(styles.sourceIcon, styles.commonIcon)}>
                    <img className={styles.sourceIconImg} src={trafficsLogo} alt="Traffics source" />
                  </span>
                  <span className={styles.sourceLogo}>
                    <img className={styles.sourceLogoImg} src={trafficsSource} alt="Traffics logo" />
                  </span>
                </div>
                <div className={styles.sourceItem}>
                  <span className={cn(styles.sourceIcon, styles.commonIcon)}>
                    <img className={styles.sourceIconImg} src={nezasaLogo} alt="Nezasa source" />
                  </span>
                  <span className={styles.sourceLogo}>
                    <img className={styles.sourceLogoImg} src={nezasaSource} alt="Nezasa logo" />
                  </span>
                </div>
                <div className={styles.sourceItem}>
                  <IconItem
                    className={styles.anyIcon}
                    label={formatMessage(messages.icon4Label)}
                    color="tertiary"
                    icon="icon-infinity"
                  />
                </div>
                <img className={styles.arrowSources} src={arrowSources} alt="infographic arrows" />
              </div>
            </ScrollAnimation>
          </div>
          <div className={styles.colRight}>
            <ScrollAnimation animateIn="fadeIn" animateOnce delay={300}>
              <picture className={styles.productImg}>
                <source media={'(min-width: 1440px)'} srcSet={`${productDesktop} 2x, ${productDesktop} 3x`} />
                <source media={'(min-width: 768px)'} srcSet={`${productTablet} 1x, ${productTablet2x} 2x`} />
                <source media={'(max-width: 767px)'} srcSet={`${productMobile} 1x, ${productMobile2x} 2x`} />
                <img className={styles.productImg} src={productDesktop} alt="product image" />
              </picture>
            </ScrollAnimation>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(TsboInfographic)
